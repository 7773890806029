<template>
  <v-card :loading="loading" outlined>
    <v-toolbar v-if="messageId != null" color="titleBar" dark flat dense>
      <v-toolbar-title>
        {{ message.title }}
      </v-toolbar-title>
    </v-toolbar>

    <v-card-text v-if="messageId != null">
      <div v-html="message.text" class="pa-2" />
      <v-divider
        v-if="message.documents != null && message.documents.length > 0"
        class="mb-2"
      />
      <v-row
        v-for="doc in message.documents"
        :key="doc.id"
        no-gutters
        align="center"
        align-content="center"
      >
        <v-col cols="1">
          <v-tooltip right>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                icon
                color="primary"
                @click="downloadDocument(doc)"
              >
                <v-icon small>mdi-download</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("download") }}</span>
          </v-tooltip>
        </v-col>
        <v-col cols="11" class="text-caption">
          {{ doc.title || doc.filename }}
        </v-col>
      </v-row>
    </v-card-text>

    <validation-observer v-else ref="obs" v-slot="{ errors, invalid }">
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <validation-provider
              vid="title"
              :name="$t('title')"
              rules="required|max:100"
              v-slot="{ errors, valid, dirty, classes }"
            >
              <v-text-field
                v-model="message.title"
                name="title"
                :label="$t('title')"
                :error-messages="errors"
                :success="dirty && valid"
                :class="classes"
                :disabled="message.is_incoming"
                counter="100"
                :clearable="!isMobile"
              />
            </validation-provider>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <validation-provider vid="text" :name="$t('text')" rules="required">
              <wysiwyg-editor v-model="message.text" :renderTables="false"/>
            </validation-provider>
          </v-col>
        </v-row>

        <v-card class="pa-8 my-6" outlined>
          <v-row
            v-for="(doc, idx) in message.documents"
            :key="idx"
            align="center"
            justify="center"
            dense
          >
            <v-col cols="6">
              <v-text-field
                v-if="doc.id != null"
                v-model="doc.title"
                name="document.title"
                :label="$t('title')"
                disabled
              />
              <validation-provider
                v-else
                vid="document.title"
                :name="$t('title')"
                rules="max:200"
                v-slot="{ errors, classes }"
              >
                <v-text-field
                  v-model="doc.title"
                  name="document.title"
                  :label="$t('title')"
                  :error-messages="errors"
                  :class="classes"
                  :disabled="message.is_incoming"
                  :clearable="!isMobile"
                />
              </validation-provider>
            </v-col>
            <v-col cols="5">
              <v-text-field
                v-if="doc.id != null"
                v-model="doc.filename"
                disabled
              />
              <validation-provider
                v-else
                vid="document.content"
                :name="$t('file')"
                :rules="
                  (doc.title != null ? 'required|' : '') +
                  (doc.file != null ? 'size:' + maxFileSize : '')
                "
                v-slot="{ errors, classes }"
              >
                <v-file-input
                  v-model="doc.file"
                  name="document.content"
                  :label="$t('file')"
                  :error-messages="errors"
                  :class="classes"
                  :disabled="message.is_incoming"
                  :clearable="!isMobile"
                  show-size
                  @change="convertToBase64(doc, $event)"
                />
              </validation-provider>
            </v-col>
            <v-col cols="1">
              <v-tooltip
                bottom
                v-if="doc.id == null && idx + 1 < message.documents.length"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                    :loading="doc.deleting"
                    :disabled="message.is_incoming"
                    @click="deleteDocument(doc, idx)"
                    icon
                  >
                    <v-icon>mdi-trash-can-outline</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("delete attachment") }}</span>
              </v-tooltip>
              <v-tooltip bottom v-else>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                    :loading="doc.adding"
                    :disabled="message.is_incoming"
                    @click="appendDocument"
                    icon
                  >
                    <v-icon>mdi-plus-circle</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("add attachment") }}</span>
              </v-tooltip>
              <v-tooltip bottom v-if="doc.id != null">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                    icon
                    :loading="doc.downloading"
                    @click="downloadDocument(doc)"
                  >
                    <v-icon small>mdi-download</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("download attachment") }}</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-card>
      </v-card-text>
      <v-card-actions v-if="messageId == null">
        <v-spacer></v-spacer>
        <v-btn
          text
          @click="$router.push({ name: 'doc-and-msg' })"
          :disabled="loading || loadingDocuments"
        >
          {{ $t("cancel") }}
        </v-btn>
        <v-btn
          color="primary"
          :disabled="invalid || loading || loadingDocuments"
          :loading="loading || loadingDocuments"
          @click="createMessage"
        >
          {{ $t("send") }}
        </v-btn>
      </v-card-actions>
    </validation-observer>
  </v-card>
</template>

<script>
import wysiwygEditor from "@/components/basics/wysiwygEditor";
import isMobile from "@/utils/mixins/isMobile";
import axios from "axios";

const emptyDocument = {
  id: null,
  title: null,
  filename: null,
  file: null,
};

export default {
  name: "Documents",
  props: {
    messageId: {
      type: [String, Number],
      default: null,
    },
  },
  mixins: [isMobile],
  components: {
    wysiwygEditor,
  },
  watch: {
    messageId: {
      immediate: true,
      handler(value) {
        if (value != null) this.getMessage();
      },
    },
  },
  data: () => ({
    loading: false,
    loadingDocuments: false,
    message: {
      id: null,
      title: null,
      text: null,
      documents: [{ title: null, file: null }],
    },
    maxFileSize: 100000, // 100 MB
  }),
  methods: {
    convertToBase64(doc, file) {
      if (file != null) {
        doc.base64 = null;
        doc.filename = file.name;
        const fileReader = new FileReader();
        fileReader.addEventListener("load", function (event) {
          doc.base64 = event.target.result;
        });
        fileReader.readAsDataURL(doc.file);
      }
    },
    getMessage() {
      var that = this;
      that.loading = true;
      this.$http
        .get("messages/" + this.messageId)
        .then((response) => {
          that.message = { ...response.data };
          if (response.data.client != null)
            that.message.client = response.data.client.number;
          that.message.documents = [
            ...that.message.documents,
            { ...emptyDocument },
          ];
        })
        .catch((err) => {
          that.$store.commit("setSystemError", {
            msg: err.message,
            title: err.title,
          });
        })
        .finally(function () {
          that.loading = false;
        });
    },
    createMessage() {
      var that = this;
      var documents = this.message.documents;
      that.loading = true;
      this.$http
        .post("messages", {
          title: this.message.title,
          text: this.message.text,
        })
        .then((response) => {
          that.message = response.data;
          that.syncDocuments(documents);
        })
        .catch((err) => {
          that.$store.commit("setSystemError", {
            msg: err.message,
            title: err.title,
          });
        })
        .finally(function () {
          that.loading = false;
        });
    },
    syncDocuments(documents) {
      var that = this;
      var requests = [];
      for (let i = 0; i < documents.length; i++) {
        let doc = documents[i];
        if (doc.id == null && doc.file != null) {
          requests.push(
            this.$http.post("messages/" + this.message.id + "/documents", {
              title: doc.title,
              content: doc.base64 || null,
              filename: doc.filename,
            })
          );
        }
      }
      if (requests.length > 0) {
        this.loadingDocuments = true;
        axios
          .all(requests)
          .then((responseArray) => {
            that.$router.push({
              name: "doc-and-msg",
              params: { messageId: this.message.id },
            });
          })
          .catch((err) => {
            that.$store.commit("setSystemError", {
              msg: err.message,
              title: err.title,
            });
          })
          .finally(function () {
            that.loadingDocuments = false;
          });
      } else
        this.$router.push({
          name: "doc-and-msg",
          params: { messageId: this.message.id },
        });
    },
    appendDocument() {
      this.message.documents = [
        ...this.message.documents,
        { ...emptyDocument },
      ];
    },
    deleteDocument(doc, idx) {
      var that = this;
      doc.deleting = true;
      this.message.documents.splice(idx, 1);
      this.message.documents = [...this.message.documents];
    },
    downloadDocument(doc) {
      this.downloadFile(
        "messages/" + this.message.id + "/documents/" + doc.id + "/download",
        "get",
        {},
        doc.filename
      );
    },
  },
};
</script>

<i18n>
  {
    "en": {
      "title": "Title",
      "text": "Text",
      "download": "download",
      "file": "File",
      "add attachment": "add attachment",
      "delete attachment": "delete attachment",
      "cancel": "Cancel",
      "send": "Send"
    },
    "de": {
      "title": "Titel",
      "text": "Text",
      "download": "herunterladen",
      "file": "Datei",
      "add attachment": "Anhang hinzufügen",
      "delete attachment": "Anhang löschen",
      "cancel": "Abbrechen",
      "send": "Senden"
    }
  }
</i18n>
